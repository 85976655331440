import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'
import { AssetT } from './assets.slice'

type Stats = {
    graphics: number
    exported: number
    assets: AssetT[]
}
type CompanyStats = {
    id: string
} & Stats

type AssetPayload = {
    id: string
    asset: AssetT
}

type CompanyStatsState = {
    data: Record<string, CompanyStats>
    status?: string
}

const initialState: CompanyStatsState = {
    data: {},
}
export const companyStatsSlice = createSlice({
    name: 'companyStats',
    initialState,
    reducers: {
        getCompanyStatsPending: (state: CompanyStatsState, action: PayloadAction<CompanyStats>) => {
            const id = action.payload.id
            state.data = { ...state.data, [id]: action.payload }
        },
        getCompanyStatsFulfilled: (
            state: CompanyStatsState,
            action: PayloadAction<CompanyStats>
        ) => {
            state.data = { ...state.data, [action.payload.id]: action.payload }
        },
        addAssetFulfilled: (state: CompanyStatsState, action: PayloadAction<AssetPayload>) => {
            state.data = {
                ...state.data,
                [action.payload.id]: {
                    ...state.data[action.payload.id],
                    assets: [action.payload.asset, ...state.data[action.payload.id].assets],
                },
            }
        },
        getCompanyStatsRejected: (state: CompanyStatsState, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    getCompanyStatsPending,
    getCompanyStatsFulfilled,
    getCompanyStatsRejected,
    addAssetFulfilled,
} = companyStatsSlice.actions

export const getCompanyStatsAsync =
    (id: string, refresh?: boolean): AppThunkT =>
    async (dispatch, getState) => {
        const { companyStats } = getState()
        if (companyStats.data[id] && !refresh) {
            return
        }

        try {
            dispatch(getCompanyStatsPending({ id, graphics: 0, exported: 0, assets: [] }))
            const data = await get<Stats>(`companies/${id}/stats`)
            dispatch(getCompanyStatsFulfilled({ id, ...data }))
        } catch (error: any) {
            dispatch(getCompanyStatsRejected(error.message))
        }
    }

export default companyStatsSlice.reducer
