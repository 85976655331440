import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import ExpandableSection from './ExpandableSection'

interface Props {
    object?: AnySceneObjectT | AnimationI
    title?: string
    handleSetValue?: (property: string, value: string | number) => void
    multiline?: boolean
    disabled?: boolean
}

const TitleHeading = ({ object, title, handleSetValue, multiline, disabled }: Props) => {
    const [value, setValue] = useState<string>(object?.title ?? title ?? '')

    useEffect(() => {
        setValue(object?.title ?? title ?? '')
    }, [object, title])

    const debouncedValue = useDebounce<string>(value)
    useEffect(() => {
        handleSetValue?.('title', debouncedValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === 'Enter' && handleSetValue) {
            handleSetValue('title', value)
        }
    }

    return (
        <ExpandableSection
            expanded={true}
            title={null}
            component={
                <TextField
                    fullWidth
                    multiline={multiline && !object}
                    type="text"
                    className="headerInput"
                    name="title"
                    value={value}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    disabled={disabled}
                    sx={{
                        '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        '& .MuiInputBase-multiline': {
                            paddingTop: multiline ? 1 : 0,
                        },
                    }}
                />
            }
        />
    )
}

export default TitleHeading
