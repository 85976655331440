import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { OBJECT } from '../../../style/sizing'

interface Props {
    title: string
    index?: number
    isActive: boolean
    handleSelectObject: (ctrlCmdKey: boolean) => void
}

const ObjectTitle = ({ title, index, isActive, handleSelectObject }: Props) => {
    const theme = useTheme()
    return (
        <Grid
            item
            onClick={(e) => handleSelectObject(e.metaKey || e.ctrlKey)}
            sx={{
                width: OBJECT.titleWidth + 'px',
                marginLeft: '10px',
                cursor: 'pointer',
            }}
        >
            <Tooltip title={title}>
                {isActive ? (
                    <Typography variant="body1" color={theme.palette.primary.main} noWrap={true}>
                        {title}
                    </Typography>
                ) : (
                    <Typography variant="body1" noWrap={true}>
                        {title}
                    </Typography>
                )}
            </Tooltip>
        </Grid>
    )
}

export default ObjectTitle
