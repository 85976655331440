import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import {
    Button,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Permission } from '../constants/permission.enum'
import { selectCanUndo, selectUndoable } from '../helpers/selector.helpers'
import { useCanvas } from '../hooks/useCanvas'
import { useCheckEditor } from '../hooks/useCheckEditor'
import useHTMLFileContent from '../hooks/useHTMLFileContent'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { useSession } from '../hooks/useSession'
import { useVerifyPermission } from '../hooks/useVerifyPermission'
import {
    clearAndReset,
    prevSaveFile,
    setOpenCanvasSettings,
    setOpenSaveForm,
    setOpenVersionHistoryDetail,
    setSignSaveAs,
} from '../store/slices/editor.slice'
import { GraphicT, deleteGraphicAsync, updateGraphicAsync } from '../store/slices/graphic.slice'
import { AssetRef } from '../store/slices/graphics.slice'
import { selectSortedRootObjects } from '../store/slices/objects.slice'
import { getSimilarGraphicAsync } from '../store/slices/similarGraphics.slice'
import ClearScene from './Editor/TopNavbar/ClearScene'
import DeleteGraphic from './Editor/TopNavbar/DeleteGraphic'
import DownloadFile from './Editor/TopNavbar/DownloadFile'
import ShowVersionHistory from './Editor/TopNavbar/ShowVersionHistory'
import UploadFile from './Editor/TopNavbar/UploadFile'
import AlertDialog from './common/AlertDialog'
import AlertMoreChoiceDialog from './common/AlertMoreChoiceDialog'
import ButtonWithTooltip from './common/ButtonWithTooltip'
import LoadingButton from './common/LoadingButton'

const TopNavbarEditorActions = () => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const { t } = useTranslation()
    const { processFile } = useHTMLFileContent()
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [openDialogToDuplicate, setOpenDialogToDuplicate] = useState<boolean>(false)
    const [openDialogToClearScene, setOpenDialogToClearScene] = useState<boolean>(false)
    const [openDelAlert, setOpenDeleteAlert] = useState(false)
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const openCanvasSettings = useAppSelector((state) => state.editor.value.openCanvasSettings)

    const anchorRef = useRef<HTMLButtonElement>(null)

    const { openMenuWizard } = useAppSelector((state) => state.editor.value)
    const { disabledShortSave } = useCheckEditor()
    const canUndo = useAppSelector((state) => selectCanUndo(state))
    const graphic = useAppSelector((state) => state.graphic)

    const { canvasResolution: canvas } = useCanvas()
    const objects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )
    const assets = useAppSelector((state) => state.assets.data)

    const { canDuplicate, canExport } = useVerifyPermission(graphic.data, graphic.data.company)
    const { hasPermission } = useSession()

    //useEffects
    useEffect(() => {
        if (objects.length !== 0) {
            setIsChanged(false)
        } else setIsChanged(true)
    }, [objects.length])

    const prevOpen = useRef(openMenu)
    useEffect(() => {
        prevOpen.current = openMenu
    }, [openMenu])

    //handlers and functions
    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

        setOpenMenu(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenMenu(false)
        } else if (event.key === 'Escape') {
            setOpenMenu(false)
        }
    }

    // Process and download HTML + JSON file Save As
    const processSave = async () => {
        const file = processFile()
        setOpenMenu(false)
        dispatch(prevSaveFile(file))
        dispatch(setOpenSaveForm(true))
    }

    // functionality when save as new / (Duplicate)
    const handleDuplicate = async () => {
        if (canUndo) {
            setOpenDialogToDuplicate(true)
        }
        processSave()
        const companyId = graphic.data.companyId
        const regex = '^' + graphic.data.name.replace(/(\()(\d+)(\))$/, () => '')
        //warning if more data use useEffect or modify slice
        await dispatch(getSimilarGraphicAsync(companyId, regex))
        dispatch(setOpenVersionHistoryDetail(false))
        dispatch(setSignSaveAs(true))
    }

    // functionality when regular save
    const handleOpenSaveForm = async () => {
        processSave()
        dispatch(setSignSaveAs(false))
    }
    const handleSaveShortCall = async (
        data: GraphicT,
        canvas: CanvasI,
        objects: AnySceneObjectT[],
        reset: boolean
    ) => {
        const file = processFile()
        let assetsRef: AssetRef[] = assets.map((asset) => ({ id: asset._id }))
        setOpenMenu(false)
        await dispatch(prevSaveFile(file))
        await dispatch(updateGraphicAsync({ ...data, assetsRef }, file, reset))
    }
    const handleCloseAlert3ChoiceDialog = () => {
        setOpenDialogToDuplicate(false)
        setOpenDialogToClearScene(false)
        dispatch(setOpenSaveForm(false))
        dispatch(setOpenVersionHistoryDetail(false))
    }

    const navigate = useNavigate()
    const handleContinueWithoutSaving = async () => {
        if (openDialogToDuplicate) {
            setOpenDialogToDuplicate(false)
            //TO DO after clear scene do not clear id from URL
        } else if (openDialogToClearScene) {
            await clearScene()
            setOpenDialogToClearScene(false)
            dispatch(setOpenCanvasSettings(true))
        }
    }
    const handleSaveAndContinue = async (
        data: GraphicT,
        canvas: CanvasI,
        objects: AnySceneObjectT[]
    ) => {
        //save changes and continues in Duplicate GraphicSaveForm
        if (openDialogToDuplicate) {
            handleSaveShortCall(data, canvas, objects, false)
            setOpenDialogToDuplicate(false)
        }
        //save changes and continues to clear Scene
        // [NOTE]
        // TO DO after clearScene did not clear state in graphic.data
        else if (openDialogToClearScene) {
            await handleSaveShortCall(data, canvas, objects, true)
            navigate('/app/editor')
            setOpenDialogToClearScene(false)
            dispatch(setOpenCanvasSettings(true))
        }
    }

    const handleCreateNew = async () => {
        setOpenMenu(false)
        if (canUndo) {
            setOpenDialogToClearScene(true)
        } else {
            dispatch(setOpenSaveForm(false))
            dispatch(setOpenVersionHistoryDetail(false))
            await clearScene()
            dispatch(setOpenCanvasSettings(true))
        }
    }
    const clearScene = async () => {
        await dispatch(clearAndReset(graphic.data._id ?? graphic.data.tmpId))
        navigate('/app/editor')
        setOpenDialogToClearScene(false)
    }

    const onDelete = async () => {
        setOpenDeleteAlert(false)
        if (graphic.data._id) await dispatch(deleteGraphicAsync(graphic.data._id))
        await dispatch(clearAndReset())
        navigate('/app/editor')
        dispatch(setOpenCanvasSettings(true))
    }

    const handleDelete = () => {
        setOpenDeleteAlert(true)
        setOpenMenu(false)
    }

    const handleLeaveCreateNewAlertDialog = async () => {
        setOpenDialogToClearScene(false)
        await clearScene()
        dispatch(setOpenCanvasSettings(true))
    }

    if (location.pathname === '/app/editor') {
        return (
            <Grid container flexDirection="row" alignItems="center">
                <Grid item>
                    {!graphic.status && !graphic.data.name ? (
                        <Skeleton variant="text" width={120} height={60} />
                    ) : (
                        <ButtonWithTooltip
                            tooltipTitle={t('menu:editDetail')}
                            buttonComponent={
                                <span>
                                    <Button
                                        disabled={!graphic.data.name}
                                        variant="text"
                                        color="inherit"
                                        sx={{
                                            p: 1,
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                        onClick={handleOpenSaveForm}
                                    >
                                        <i>{!graphic.data.name ? t('menu:untitled') : ''}</i>
                                        {graphic.data.name ? graphic.data.name : ''}
                                    </Button>
                                </span>
                            }
                            disabled={false}
                        />
                    )}
                </Grid>
                {!graphic.data._id ? (
                    <LoadingButton
                        disabled={isChanged || !graphic.status}
                        size="large"
                        loading={!graphic.status}
                        loadingPosition="end"
                        variant="text"
                        onClick={handleOpenSaveForm}
                        tooltipTitle={t('menu:save')}
                        color="inherit"
                        endIcon={<SaveAsRoundedIcon />}
                    ></LoadingButton>
                ) : (
                    <LoadingButton
                        disabled={disabledShortSave}
                        size="large"
                        loading={!graphic.status}
                        loadingPosition="end"
                        variant="text"
                        onClick={() => handleSaveShortCall(graphic.data, canvas, objects, false)}
                        tooltipTitle={t('menu:save')}
                        color="secondary"
                        endIcon={<SaveRoundedIcon />}
                    ></LoadingButton>
                )}
                <ButtonWithTooltip
                    tooltipTitle={t('menu:moreContextMenu')}
                    buttonComponent={
                        <IconButton
                            aria-label="menu"
                            size="large"
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={openMenu ? 'composition-menu' : undefined}
                            aria-expanded={openMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <MoreVertRoundedIcon />
                        </IconButton>
                    }
                    disabled={false}
                />
                <Popper
                    id="editor-step-7"
                    open={openMenu || openMenuWizard}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={openMenu}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem key="createNew" onClick={handleCreateNew}>
                                            <ListItemIcon>
                                                <NoteAddIcon />
                                            </ListItemIcon>
                                            <ListItemText>{t('menu:createNew')}</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem
                                            key="duplicate"
                                            onClick={handleDuplicate}
                                            disabled={!canDuplicate || !graphic.data._id}
                                        >
                                            <ListItemIcon>
                                                <ContentCopyIcon />
                                            </ListItemIcon>
                                            <ListItemText>{t('menu:duplicate')}</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <ShowVersionHistory
                                            label={t('menu:showVersionHistory')}
                                            disabled={!graphic.data._id}
                                            onClick={() => setOpenMenu(false)}
                                        />
                                        <Divider />
                                        <DownloadFile
                                            label={t('menu:exportZip')}
                                            graphic={graphic.data}
                                            onClick={() => setOpenMenu(false)}
                                            disabled={!canExport}
                                            pack
                                        />
                                        <DownloadFile
                                            label={t('menu:exportHtml')}
                                            graphic={graphic.data}
                                            onClick={() => setOpenMenu(false)}
                                            disabled={!canExport}
                                        />

                                        {hasPermission(Permission.Graphics) && (
                                            <UploadFile
                                                label={t('menu:import')}
                                                onClick={() => setOpenMenu(false)}
                                                disabled={!hasPermission(Permission.Graphics)}
                                            />
                                        )}
                                        <Divider />
                                        <ClearScene
                                            label={t('menu:clear')}
                                            onClick={() => setOpenMenu(false)}
                                        />
                                        <DeleteGraphic
                                            label={t('menu:delete')}
                                            onClick={handleDelete}
                                            disabled={!graphic.data._id}
                                        />
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <AlertDialog
                    open={openDialogToClearScene && !graphic.data._id && openCanvasSettings}
                    title={t('editor:discardTitle')}
                    content={t('editor:discardContent')}
                    onDisagree={() => setOpenDialogToClearScene(false)}
                    onAgree={() => handleLeaveCreateNewAlertDialog()}
                    txtDisagree={t('disagree')}
                    txtAgree={t('leave')}
                ></AlertDialog>
                <AlertMoreChoiceDialog
                    open={openDialogToDuplicate || (openDialogToClearScene && !!graphic.data._id)}
                    title={`${t('editor:titleUnsavedChanges')} '${
                        graphic.data.name ? graphic.data.name : t('menu:untitled')
                    }' ?`}
                    content={t('editor:contentUnsavedChanges')}
                    onClose={() => handleCloseAlert3ChoiceDialog()}
                    buttonArray={[
                        {
                            key: 'disagree',
                            onclick: () => handleCloseAlert3ChoiceDialog(),
                            txt: t('disagree'),
                            variant: 'text',
                            color: 'inherit',
                        },
                        {
                            key: 'continue-without-saving',
                            onclick: () => handleContinueWithoutSaving(),
                            txt: t('editor:continueWithoutSaving'),
                            variant: 'text',
                            color: 'inherit',
                        },
                        {
                            key: 'save-changes',
                            onclick: () => handleSaveAndContinue(graphic.data, canvas, objects),
                            txt: t('editor:saveChanges'),
                            variant: 'contained',
                            color: 'primary',
                            disabled: !Boolean(graphic.data._id),
                        },
                    ]}
                />
                <AlertDialog
                    open={openDelAlert}
                    title={`${t('graphics:delete')} '${graphic.data.name}' ?`}
                    content={t('graphics:deleteQuestion')}
                    onDisagree={() => setOpenDeleteAlert(false)}
                    onAgree={() => onDelete()}
                    txtDisagree={t('disagree')}
                    txtAgree={t('delete')}
                    colorAgree="error"
                />
            </Grid>
        )
    } else {
        return null
    }
}

export default TopNavbarEditorActions
