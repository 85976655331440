import { selectUndoable } from '../../../helpers/selector.helpers'
import { useAnimationActions } from '../../../hooks/useAnimationActions'
import useObject from '../../../hooks/useObject'
import { useAppSelector } from '../../../hooks/useRedux'
import { selectAnimationById, selectAnimationsByIds } from '../../../store/slices/animations.slice'
import { ScrollableContainer } from '../../../style/styles'
import TitleHeading from '../../common/TitleHeading'
import AnimationTween from './AnimationTools/AnimationTween'
import Easing from './AnimationTools/Easing'
import Timing from './AnimationTools/Timing'

interface Props {
    animationId: string
}

const AnimationTools = ({ animationId }: Props) => {
    const animation: AnimationI = useAppSelector((state) =>
        selectAnimationById(selectUndoable(state).animations, animationId)
    )
    const { findObjectById } = useObject()
    const selectedAnimationIds = useAppSelector((state) => state.activeAnimation.selected)
    const animations = useAppSelector((state) => selectUndoable(state).animations)
    const getSelectedAnimations = (): AnimationI[] => {
        return selectAnimationsByIds(animations, selectedAnimationIds)
    }

    const { updateAnimation } = useAnimationActions()
    const setValue = (property: any, value: any) => updateAnimation(animationId, property, value)
    const setValues = (animations: AnimationI[], property: any, value: any) => {
        Object.values(animations).forEach((animation: AnimationI) =>
            updateAnimation(animation.id, property, value)
        )
    }
    const isSequenceType = animation.tween.name === 'sequence'

    const getSelectedAnimationsTitle = (): string => {
        let result: string[] = []
        Object.values(getSelectedAnimations()).forEach((animation: AnimationI) => {
            if (animation.tween.name === 'sequence') {
                let object = findObjectById(animation.objectId)
                if (object)
                    if (!result.includes(object?.title)) {
                        result.push(object?.title)
                    }
            } else {
                result.push(animation.tween.name)
            }
        })
        return result.join(', ')
    }

    return (
        <ScrollableContainer id="editor-step-4">
            {animation && (
                <div>
                    <TitleHeading
                        multiline={selectedAnimationIds.length > 1}
                        object={selectedAnimationIds.length <= 1 ? animation : undefined}
                        handleSetValue={selectedAnimationIds.length <= 1 ? setValue : undefined}
                        title={
                            selectedAnimationIds.length <= 1
                                ? undefined
                                : getSelectedAnimationsTitle()
                        }
                        disabled={isSequenceType}
                    />

                    <Timing
                        animation={animation}
                        selectedAnimations={getSelectedAnimations()}
                        handleSetValue={setValue}
                        handleSetValues={setValues}
                    />
                    {!isSequenceType && selectedAnimationIds.length < 2 && (
                        <Easing
                            animation={animation}
                            selectedAnimations={getSelectedAnimations()}
                            handleSetValue={setValue}
                            handleSetValues={setValues}
                        />
                    )}
                    {!isSequenceType && selectedAnimationIds.length < 2 && (
                        <AnimationTween animation={animation} handleSetValue={setValue} />
                    )}
                </div>
            )}
        </ScrollableContainer>
    )
}

export default AnimationTools
