import { useEffect } from 'react'
import { getCompanyStatsAsync } from '../store/slices/companyStats.slice'
import { useEditorSettings } from './useEditorSettings'
import { useAppDispatch, useAppSelector } from './useRedux'

export const useCompanyStats = ({ id }: { id: string | undefined }) => {
    const dispatch = useAppDispatch()
    const stats = useAppSelector((state) => (id ? state.companyStats.data[id] : undefined))

    const exportsInCompany = stats?.exported ?? 0

    const numberOfGraphicInCompany = stats?.graphics ?? '-'

    useEffect(() => {
        if (id) dispatch(getCompanyStatsAsync(id))
    }, [dispatch, id])

    return { exportsInCompany, numberOfGraphicInCompany }
}

export const useCurrentCompanyStats = () => {
    const { companyId } = useEditorSettings()

    return useAppSelector((state) => state.companyStats.data[companyId] ?? { assets: [] })
}
